import _ from "lodash";
import React from "react";
import { CookieBanner } from "@palmabit/react-cookie-law";
import Settings from "../../settings";
import Cookies from "../../utils/cookies";
import { i18n } from "../../config";

import { Button } from "@material-ui/core"

class CookieDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreferences: false,
      showBanner: true,
    };
  }

  acceptAllCookies = () => {
    this.setState({ showBanner: false });
    Cookies.acceptAllCookies();
  };

  personalizeCookies = () => {
    this.setState({ showPreferences: true });
    window.requestAnimationFrame(() => {
      document.getElementById("check-statistics-cookies").click();
      document.getElementById("check-preferences-cookies").click();
    })
  };

  componentDidMount() {
    this.setState({ showBanner: !Cookies.hasAcceptedCookies() });
  }

  render() {

    const preView = (
      <div className="default-cookies-dialog">
        <div className="information-text">{i18n.t('common:text.cookie_consent_text')}</div>
        <div className="container">
          <p className="container-element link">
            <a href={Settings.links.cookies}>{i18n.t('common:text.cookie_policy_text')}</a>
          </p>
          <Button
            color={'primary'}
            variant={'contained'}
            className="container-element"
            onClick={this.acceptAllCookies}
          >
            {i18n.t('common:text.cookie_accept_all_text')}
          </Button>
          <Button
            variant={'contained'}
            className="container-element"
            onClick={this.personalizeCookies}
          >
            {i18n.t('common:text.cookie_decide_which_text')}
          </Button>
        </div>
      </div>
    );

    const detailedView = (
      <CookieBanner
        className="cookie-dialog"
        message={i18n.t('common:text.cookie_consent_text')}
        acceptButtonText={i18n.t('common:text.cookie_consent_button')}
        policyLink={_.get(Settings, "links.cookies", "")}
        privacyPolicyLinkText={i18n.t('common:text.cookie_policy_text')}
        necessaryOptionText={i18n.t('common:text.cookie_necessary_text')}
        preferencesOptionText={i18n.t('common:text.cookie_preferences_text')}
        statisticsOptionText={i18n.t('common:text.cookie_statistics_text')}
        marketingOptionText={i18n.t('common:text.cookie_marketing_text')}
        onAcceptStatistics={Cookies.acceptStatisticCookies}
      />
    );

    return (
      <div className="wrapper" role="contentinfo">
        {this.state.showBanner && !this.state.showPreferences && preView}
        {this.state.showBanner && this.state.showPreferences && detailedView}
      </div>
    );
  }
}

export default CookieDialog;
