import React from 'react';
import { connect } from 'react-redux';
import {editContract, setATRAvailable} from "../../actions/contracts";
import validate from "validate.js";
import SignatureProcess from "../SignatureProcess";
import ErrorMessage from "../ErrorMessage";
import {data_create_api_resource} from "../../utils/http_functions";
import jwt_decode from 'jwt-decode';
import { i18n } from "../../config";

import { CircularProgress, Button, TextField, FormHelperText } from '@material-ui/core';


const VALIDATIONS = {
  phone: {
    presence: {allowEmpty: false}
  },
  power_p1: {
    presence: {allowEmpty: false},
    numericality: {
      greaterThan: 0
    }
  },
  power_p2: {
    numericality: function(value) {
      if (value || isNaN(value)) {
        return {greaterThan: 0}
      }
    }
  },
  power_p3: {
    numericality: function(value) {
      if (value || isNaN(value)) {
        return {greaterThan: 0}
      }
    }
  },
  power_p4: {
    numericality: function(value) {
      if (value || isNaN(value)) {
        return {greaterThan: 0}
      }
    }
  },
  power_p5: {
    numericality: function(value) {
      if (value || isNaN(value)) {
        return {greaterThan: 0}
      }
    }
  },
  power_p6: {
    numericality: function(value) {
      if (value || isNaN(value)) {
        return {greaterThan: 0}
      }
    }
  }
};

const STATUS = {
  INIT: 'init',
  SENDING: 'sending',
  SENT: 'sent',
  ERROR: 'error'
};


const mapStateToProps = (state) => ({
  contract: state.contracts.view_contract,
  token: state.auth.token,
});


class ChangePower extends React.Component {
  constructor(props) {
    super(props);
    const token_values = jwt_decode(this.props.token);
    this.state = {
      phone: this.props.contract.notification_address.mobile,
      powers: {},
      initial_powers: {},
      status: STATUS.INIT,
      errors: {},
      waiting_for_signature: false
    };
    this.props.power_periods.forEach((pp) => {
      const period = 'power_' + pp.period.toLowerCase();
      this.state.powers[period] = pp.power.toString().replace(/\./g, ',');
      this.state.initial_powers[period] = pp.power.toString().replace(/\./g, ',');
    });
    validate.validators.numericality.options = {message: i18n.t('common:text.power_modification_validation')};
  }

  handleSendPower = async () => {
    const value = {
      phone: this.state.phone
    };
    Object.keys(this.state.powers).forEach((key) => {
      value[key] = (+this.state.powers[key].replace(/,/g, '.')) * 1000
    });
    let errors = validate(value, VALIDATIONS);
    Object.keys(this.state.powers).forEach((key) => {
      if (this.state.powers[key] === this.state.initial_powers[key]) { errors = {...errors, [key]: i18n.t('common:text.power_modification_same')} };
    });
    if (errors) {
      this.setState({errors});
    } else {
      this.setState({status: STATUS.SENDING});
      const { token, contract } = this.props;
      try {
        const result = await data_create_api_resource(
          token, `contracts/${ contract.name }/power/`, value
        );
        const { waiting_for_signature } = result.data;
        this.props.setATRAvailable(false);
        this.setState({waiting_for_signature});
        if (!waiting_for_signature) {
          this.handleClose();
        }
        this.setState({status: STATUS.SENT});
      } catch (e) {
        if (e.response.status === 400 && e.response && e.response.data.errors) {
          this.setState({
            status: STATUS.INIT,
            errorCode: e.response.status,
            errors: e.response.data.errors
          });
        }
        if (e.response.status === 406 && e.response && e.response.data.error) {
          this.setState({
            status: STATUS.ERROR,
            errorCode: e.response.status,
            errors: e.response.data.error
          });
        } else {
          this.setState({status: STATUS.ERROR});
        }
      }
    }
  };

  handleClose = () => {
    this.props.editContract(false);
  };

  handleChangePower = (event) => {
    let name = event.target.name;
    let value = event.target.value.replace(/\./g, ',');
    const power = {};
    power[name] = value;
    const powers = {...this.state.powers, ...power};
    this.setState({powers});
  };

  renderSent() {
    const {status, waiting_for_signature} = this.state;
    return (
      <div>
        { waiting_for_signature && <SignatureProcess/> }
        { status === STATUS.ERROR && this.state.errorCode !== 406 && <ErrorMessage/> }
        { status === STATUS.ERROR && this.state.errorCode === 406 && <ErrorMessage children={i18n.t('common:text.power_same_error_modification_close')}/> }
        <Button
          variant={'contained'}
          style={{ marginTop: '12px' }}
          color={'primary'}
          onClick={this.handleClose}
        >
          {i18n.t('common:text.power_modification_close')}
        </Button>
      </div>
    );
  }

  renderForm() {

    const { status, errors } = this.state;
    const sending = status === STATUS.SENDING;

    const powersInputs = Object.keys(this.state.powers).map((key) => {
      const value = this.state.powers[key];
      const period = key.split('_')[1];
      return (
        <div>
          <TextField
            label={`Potencia ${period} (kW)`}
            name={key}
            value={value}
            onChange={this.handleChangePower}
            disabled={sending}
            error={!!(errors[key])} />
          {(errors[key]) &&
            <FormHelperText error={true}>
              {errors[key]}
            </FormHelperText>
          }
        </div>
      );

    });

    return (
      <div>
        {powersInputs}
        <div>
          <TextField 
            label={i18n.t('common:text.power_modification_phone')}
            value={this.state.phone}
            onChange={(e) => this.setState({ phone: e.target.value })}
            error={!!(errors.phone)}
            disabled={this.state.status === STATUS.SENDING} />
          {(errors && errors.phone) &&
            <FormHelperText error={true}>
              {errors.phone}
            </FormHelperText>
          }
        </div>
        <div style={{marginTop: '12px'}}>
          <Button
            style={{ marginRight: '12px' }}
            variant={'contained'}
            onClick={this.handleClose}
            disabled={sending}>
            {i18n.t('common:text.power_modification_close')}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={this.handleSendPower}
            disabled={sending}>
            {sending ? <CircularProgress size={25} /> : null}
            {i18n.t('common:text.power_modification_activate')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { status } = this.state;
    return (
      <div>
        <h2>{ i18n.t('common:text.power_modification_title') }</h2>
        { (status === STATUS.INIT || status === STATUS.SENDING) && this.renderForm() }
        { (status === STATUS.SENT || status === STATUS.ERROR) && this.renderSent() }
      </div>
    );
  };

}

export default connect(mapStateToProps, { editContract, setATRAvailable })(ChangePower);
