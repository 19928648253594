import _ from "lodash";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/auth";
import { connect } from "react-redux";

import { Grid, TextField, Button, Paper, Typography, Box } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LoginIcon from '@material-ui/icons/ExitToApp';
import Alert from "../components/Alert";

import Settings from "../settings";
import { i18n } from "../config";


function mapStateToProps(state) {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    statusType: state.auth.statusType,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const styles = {
  logo: {
    paddingLeft: "auto",
    paddingRight: "auto",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  logoImage: {
    maxWidth: "300px",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 300,
    },
  },
}));

const LoginView = (props) => {

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const classes = useStyles();

  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  const handleLogin = (event, token = false) => {
    event.preventDefault();
    performLogin(token);
  };

  const performLogin = (token = false) => {
    let next = "/";
    if (
      "" !== props.location.search && // url has additional query params 
      props.location.search.includes(i18n.t('common:url.next')) // additional query params include keyword to redirect to another page after login
    ) {
      const next_param = props.location.search.split(i18n.t('common:url.next') + "=").at(-1);
      next = next_param ? next_param : "/";
    }

    if (token) {
      props.loginToken(token, next);
    } else {
      props.loginUser(user, password, next);
    }
  };

  useEffect(() => {
    if ( props.location.search.search("token") > -1 ) {
        const token = props.location.search.split("token=")[1].split("&")[0];
        performLogin(token);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      onKeyPress={handleKeyPress}
      role="main"
    >
      <Grid item>
        <Paper className="login-paper">
          <div className="login-paper-inner">
            <div>
              <div id="image" style={styles.logo}>
                <img src="/images/logo.png" alt="Logo" style={styles.logoImage} />
              </div>
            </div>
            <form className={classes.root}>
              <div>
                <Box my={2}>
                  <Typography variant="h5" aria-level="1">
                    {i18n.t('common:text.login_view_title')}
                  </Typography>
                </Box>
                {props.statusText && (
                  <Alert severity={props.statusType}>
                    {props.statusText}
                  </Alert>
                )}
                <div>
                  <TextField
                    placeholder={i18n.t('common:text.login_view_field_email_hint')}
                    label={i18n.t('common:text.login_view_field_email_title')}
                    variant="outlined"
                    onChange={handleChangeUser}
                  />
                </div>
                <div>
                  <TextField
                    placeholder={i18n.t('common:text.login_view_field_password_title')}
                    label={i18n.t('common:text.login_view_field_password_title')}
                    type="password"
                    variant="outlined"
                    onChange={handleChangePassword}
                  />
                </div>
                <div style={{ marginTop: 15 }}>
                  <Button
                    onClick={handleLogin}
                    color="primary"
                    variant="contained"
                    startIcon={<LoginIcon />}
                    style={{marginBottom: 10}}
                  >
                      {i18n.t('common:text.login_view_button_send')}
                  </Button>
                  {_.get(Settings, "features.signup") && (
                    <Button
                      style={{ marginLeft: 10, marginBottom: 10 }}
                      href={i18n.t('common:url.activate')}
                      variant="contained"
                      startIcon={<PersonAddIcon />}
                    >
                        {i18n.t('common:text.login_view_button_activate')}
                    </Button>
                  )}
                </div>
                <div style={{ marginTop: 10 }}>
                  <Link to={i18n.t('common:url.resetPassword')}>
                    {i18n.t('common:text.login_view_reset_password')}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);

LoginView.propTypes = {
  loginUser: PropTypes.func,
  statusText: PropTypes.string,
  statusType: PropTypes.string,
};
