import React from "react";
import Settings from "../../settings";
import { connect } from 'react-redux';
import { setInvoiceOnline } from "../../actions/contracts";
import {data_create_api_resource} from "../../utils/http_functions";
import ErrorMessage from "../ErrorMessage";
import { CircularProgress, Button,
          Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { i18n } from "../../config";

const mapStateToProps = (state) => ({
  token: state.auth.token,
  contract: state.contracts.view_contract
});


const InvoiceOnlineConfirm = (props) => {
  const actions = [
    <Button
      key={'close'}
      variant={'text'}
      color={'primary'}
      onClick={props.handleClose}
      disabled={props.sending}
    >
      {i18n.t('common:text.online_send_close')}  
    </Button>,
    props.children ? null : 
    props.modifyInvoiceOnlineOneSide?
      !props.invoiceOnline?
      <Button
        key={'send'}
        variant={'text'}
        color={'primary'}
        onClick={() => props.handleSubmit(!props.invoiceOnline)}
        disabled={props.sending}
      >
        {props.sending ? <CircularProgress style={{ marginRight: 10 }} size={25} /> : null}
        {i18n.t(`common:text.online_send_${props.invoiceOnline ? 'deactivate' : 'activate'}`)}
      </Button>:null:
      <Button
        key={'send'}
        variant={'text'}
        color={'primary'}
        onClick={() => props.handleSubmit(!props.invoiceOnline)}
        disabled={props.sending}
      >
        {props.sending ? <CircularProgress style={{ marginRight: 10 }} size={25} /> : null}
        {i18n.t(`common:text.online_send_${props.invoiceOnline ? 'deactivate' : 'activate'}`)}
      </Button>,
  ];
  return (
      <Dialog 
        open={props.open} 
        disableBackdropClick={true}
        onClose={props.handleClose}
      >
        <DialogTitle>
          {i18n.t('common:text.online_send_activate_title')}
        </DialogTitle>
        <DialogContent>
          {!props.children &&
            <div>
              {i18n.t('common:text.online_send_notice')} <strong>{props.email}</strong>.
            </div>
          }
          {props.children}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
  );
};


class InvoiceOnline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      dialogOpen: false,
      error: false
    }
  }

  handleDialogOpen = () => {
    this.setState({dialogOpen: true, error: false});
  };

  handleDialogClose = () => {
    this.setState({dialogOpen: false})
  };

  handleSubmit = async (value) => {
    const { token, contract } = this.props;
    let error = false;
    this.setState({sending: true});
    try {
      await data_create_api_resource(
        token, `contracts/${ contract.name }/`, {
          invoice_online: value,
        });
      this.props.setInvoiceOnline(value);
    } catch (e) {
      error = true;
    } finally {
      this.setState({
        sending: false,
        error,
        dialogOpen: error
      })
    }
  };



  render() {
    const { contract } = this.props;
    const { dialogOpen, error, sending } = this.state;
    return (
      <div>
        {
          <span>
            {Settings.features.modifyInvoiceOnline?
            <Button 
              variant={'text'}
              color={'primary'} 
              onClick={this.handleDialogOpen}>
              {<Email style={{marginRight: 10}}/>}
              { i18n.t('common:text.online_send_activate_title') } 
            </Button>:null}
          {contract.invoice_online &&
            <div>
              { i18n.t('common:text.online_send_already') }
            </div>
          }
          </span>
        }
        <InvoiceOnlineConfirm open={dialogOpen} sending={sending} handleClose={this.handleDialogClose} handleSubmit={this.handleSubmit} email={contract.notification_address.email} invoiceOnline={contract.invoice_online} modifyInvoiceOnlineOneSide={Settings.features.modifyInvoiceOnlineOneSide}>
          {error && <ErrorMessage/>}
        </InvoiceOnlineConfirm>
      </div>
    );
  }
}

export default connect(mapStateToProps, { setInvoiceOnline })(InvoiceOnline);
