import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware  from 'redux-thunk';
import rootReducer  from '../reducers';

import * as Sentry from '@sentry/react';

import Settings from '../settings';

// Added ignore to Sentry console logs as they are unreadable and useless:
// https://github.com/getsentry/sentry-react-native/issues/794#issuecomment-908189765
if (Settings.sentry && Settings.sentry.dsn) {
    Sentry.init({
        ...Settings.sentry,
        integrations: [
            new Sentry.Integrations.Breadcrumbs({
                console: false
            })
        ],
        release: `oficina-virtual@${process.env.npm_package_version}` });
}

const debugware = [];
if (process.env.NODE_ENV !== 'production') {
    const createLogger = require('redux-logger');

    debugware.push(createLogger({
        collapsed: true,
    }));
}

export const configureStore = (initialState) => {

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware,
                ...debugware
            )
        ),
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers/index').default;

            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

export const store = configureStore();
