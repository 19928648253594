import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from "../../config";

export const LogoBar = (props) => {
  return (
    <a href={i18n.t("common:links.logo")} style={{height: "100%", display: "flex", alignItems: "center"}}>
      <img
        src="/images/logoBar.png"
        style={{
          maxHeight: "90%",
        }}
        alt="logo"
      />
    </a>
  );
};

LogoBar.propTypes = {
    openLinkInNewTab: PropTypes.bool,
};
