import React from "react";
import { connect } from 'react-redux';
import { setVirtualBatteryDialog, setVirtualBatteryPercentage } from "../../actions/contracts";
import ErrorMessage from "../ErrorMessage";
import { CircularProgress, Button,
          Dialog, DialogTitle, DialogContent, TextField, FormHelperText, DialogActions, Typography, Slider } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { i18n } from "../../config";

const STATUS = {
  INIT: 'init',
  SENDING: 'sending',
  SENT: 'sent',
  ERROR: 'error'
};

const min = 0;
const max = 100;

const mapStateToProps = (state) => ({
  token: state.auth.token,
  contract: state.contracts
});


const VirtualBatteryDialogConfirm = (props) => {

  const handleChangePesPercentatge = (event, pesPercentatge) => {
    props.setPercentatge(pesPercentatge)
  };

  const actions = [
    <Button
      key={'close'}
      variant={'text'}
      color={'primary'}
      onClick={props.handleClose}
      disabled={props.sending}
    >
      {i18n.t('common:text.online_send_close')}
    </Button>,
    props.children ? null : <Button
      key={'send'}
      variant={'text'}
      color={'primary'}
      onClick={props.handleSubmit}
      disabled={props.sending || props.percentatgeInicial==props.percentatge}
    >
      {props.sending ? <CircularProgress style={{ marginRight: 10 }} size={25} /> : null}
      {i18n.t('common:text.contract_modification')}
    </Button>,
  ];

  return (
      <Dialog 
        open={props.open} 
        onClose={props.handleClose}
      >
        <DialogTitle>
          {i18n.t('common:text.virtual_battery_modify_percentage')}
        </DialogTitle>
        <DialogContent>
          {!props.children &&
          <div>
            {i18n.t('common:text.virtual_battery_notice')}
            <br></br>
            <br></br>
            <Typography>
              {i18n.t('common:text.virtual_battery_percentage')}
            </Typography>
            <Slider
              value={props.percentatge}
              aria-labelledby="discrete-slider"
              step={0.5}
              min={0}
              max={100}
              onChange={handleChangePesPercentatge}
              valueLabelDisplay="on"
            />
          </div>
          }
          {props.children}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
  );
};


class VirtualBatteryDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      error: false,
      percentatge: props.pes_percentatge,
      percentatgeInicial: props.pes_percentatge,
    }
  }

  handleDialogOpen = () => {
    const { bateria } = this.props;
    this.setState({dialogOpen: true, error: false});
    this.setState({percentatge: bateria.pes_percentatge})
    this.setState({percentatgeInicial: bateria.pes_percentatge})
  };

  handleDialogClose = () => {
    this.setState({dialogOpen: false})
  };

  setPercentatge = (value) => {
    this.setState({percentatge: value})
  }

  handleSubmit = async () => {
    const {bateria, idx, setVirtualBatteryPercentage, changePercentatge} = this.props;
    const {percentatge} = this.state;

    let error = false;
    try {
      setVirtualBatteryPercentage(bateria.bateria.id, percentatge, idx)
    } catch (e) {
      console.log(e)
      error = true;
    } finally {
      this.setState({
        error,
        dialogOpen: error,
        percentatge: bateria.pes_percentatge,
        percentatgeInicial: bateria.pes_percentatge,
      })
    }
  };

  render() {
    const { bateria } = this.props;
    const { dialogOpen, error, sending, percentatge, percentatgeInicial } = this.state;

    return (
      <div>
        <span>
          <Button 
            variant={'contained'}
            color={'primary'} 
            onClick={this.handleDialogOpen}
            disabled={bateria.sending}>

            {bateria.sending ? <CircularProgress style={{ marginRight: 10 }} size={25} /> : <Create style={{marginRight: 10}}/>}
            { i18n.t('common:text.virtual_battery_modify_percentage') } 
          </Button>
        </span>
        <VirtualBatteryDialogConfirm open={dialogOpen} sending={sending} handleClose={this.handleDialogClose} handleSubmit={this.handleSubmit} bateriaId={bateria.bateria.id} percentatge={percentatge} setPercentatge={this.setPercentatge} percentatgeInicial={percentatgeInicial}>
          {error && <ErrorMessage/>}
        </VirtualBatteryDialogConfirm>
      </div>
    );
  }
}

export default connect(mapStateToProps, { setVirtualBatteryDialog, setVirtualBatteryPercentage })(VirtualBatteryDialog);
