import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import Settings from "../../settings";
import { i18n } from "../../config";
import AddressField, {
  validarReferenciaCatastral,
} from "../form-fields/AddressField";
import CUPSField, { cupsValid } from "../form-fields/CUPSField";
import CheckboxField from "../form-fields/CheckboxField";
import CNAEField from "../form-fields/CNAEField";
import ComerOriginSelectField from "../form-fields/ComerOriginSelectField";
import CurrentActiveContract from "../form-fields/CurrentActiveContract";
import FileUploadField, {
  validateFileUpload,
} from "../form-fields/FileUploadField";
import RichContent from "../RichContent";
import FlexRow from "../FlexRow";
import HomeIcon from '@material-ui/icons/Home';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { CircularProgress, Button, Divider, Grid, Box, Typography, Paper } from "@material-ui/core"
import ResidenceTypeSelect from "../form-fields/ResidenceTypeSelect";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutHome = (props) => {
  const [comerOriginEnabled, setComerOriginEnabled] = useState(props.selectComerOrigin);

  const handleSubmit = async (values) => {
    if(values.residenceType || values.cnae === null) {
      values.cnae = { 
        id: 986, 
        name: "9820", 
        descripcio: "9820: Actividades de los hogares como productores de servicios para uso propio" 
      }
    }
    props.submitAboutHomeData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleAvailableComersUpdate = () => {
    setComerOriginEnabled(true);
  };

  const handleUpdateResidenceType = (values) => {
    props.updateResidenceType(values);
  };

  const handleUpdateUploadedFiles = (values) => {
    props.updateUploadedFiles(values);
  };

  const handleUpdateActiveContract = (values) => {
    props.setActiveContract(values);
  };

  const handleCUPSUpdate = (values) => {
    const value = values.cups;
    if (value) {
      props.storeCUPSData(value);
      if (cupsValid(value)) {
        props.validateCUPSAvailability(values, true);
      }
    }
  };

  const { isAuthenticated } = props;

  // Set leads consent to true by default if user is authenticated
  let leadsConsent = isAuthenticated;
  if (props.leadsConsent !== null) {
    leadsConsent = props.leadsConsent;
  }

  const leadsNoteTemplate = _.template(
    i18n.t(
      _.get(Settings, 'newContract.requireLeadConsent', false) ?
        'common:text.contractation_leads_note_required'
        :
        'common:text.contractation_leads_note'
    )
  );
  const leadsNote = (
    <RichContent
      content={leadsNoteTemplate({
        client: _.get(Settings, "organization.name", ""),
        privacyPolicy: _.get(Settings, "links.privacyPolicy", ""),
      })}
    />
  );

  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  // With keepDirtyOnReinitialize we avoid the re-initialization of values problem in initialValues:
  // https://stackoverflow.com/questions/54635276/react-final-form-set-initialvalues-from-props-form-state-resets-on-props-chang
  // https://final-form.org/docs/final-form/types/Config#keepdirtyonreinitialize
  // This is a temporal solution. A permanent one would be using ReduxForm or avoiding initialsValues altogether.

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", true) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <HomeIcon fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_about_home')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          leadsConsent: leadsConsent,
          cups: props.cups,
          address: props.address,
          residenceType: props.residenceType,
          cnae: props.cnae,
          invoice: props.invoice,
          comerOrigin: props.comerOrigin,
          activeContract: props.activeContract,
        }}
        keepDirtyOnReinitialize={true}
        validate={(values) => {
          const errors = {};

          if (_.get(Settings, 'newContract.requireLeadConsent', false) && !values.leadsConsent) {
            _.set(errors, "leadsConsent", i18n.t('common:text.required_field'));
          }

          if (!values.cups) {
            errors.cups = i18n.t('common:text.required_field');
          } else if (!cupsValid(values.cups)) {
            errors.cups = i18n.t('common:text.contractation_cups_not_valid');
          }

          if (!_.get(values, "address.zipcode")) {
            _.set(errors, "address.zipcode", i18n.t('common:text.required_field'));
          } else if (values.address.zipcode.length !== 5) {
            _.set(
              errors,
              "address.zipcode",
              i18n.t('common:text.contractation_postal_code_length')
            );
          }

          // City
          if (_.isEmpty(_.get(values, "address.city", {}))) {
            _.set(errors, "address.city", i18n.t('common:text.required_field'));
          }

          // State
          if (_.isEmpty(_.get(values, "address.state"))) {
            _.set(errors, "address.state", i18n.t('common:text.required_field'));
          }

          // Street Type
          if (_.isEmpty(_.get(values, "address.tv"))) {
            _.set(errors, "address.tv", i18n.t('common:text.required_field'));
          }

          // Street Name
          if (!_.get(values, "address.nv")) {
            _.set(errors, "address.nv", i18n.t('common:text.required_field'));
          } else if (values.address.nv.length >= 256) {
            _.set(errors, "address.nv", i18n.t('common:text.address_validation_nv_length'));
          }

          // Street Number
          if (!_.get(values, "address.pnp")) {
            _.set(errors, "address.pnp", i18n.t('common:text.required_field'));
          } else if (values.address.pnp.length >= 9) {
            _.set(errors, "address.pnp", i18n.t('common:text.address_validation_pnp_length'));
          }

          // Block
          if (_.get(values, "address.bq") && values.address.bq.length >= 4) {
            _.set(errors, "address.bq", i18n.t('common:text.address_validation_bq_length'));
          }

          // Stair
          if (_.get(values, "address.es") && values.address.es.length >= 4) {
            _.set(errors, "address.es", i18n.t('common:text.address_validation_es_length'));
          }

          // Floor
          if (_.get(values, "address.pt") && values.address.pt.length >= 10) {
            _.set(errors, "address.pt", i18n.t('common:text.address_validation_pt_length'));
          }

          // Door
          if (_.get(values, "address.pu") && values.address.pu.length >= 4) {
            _.set(errors, "address.pu", i18n.t('common:text.address_validation_pu_length'));
          }

          // Referencia Catastral
          if (_.get(values, "address.ref_catastral")) {
            if (
              validarReferenciaCatastral(values.address.ref_catastral) !== 1
            ) {
              _.set(
                errors,
                "address.ref_catastral",
                i18n.t('common:text.contractation_not_valid_ref')
              );
            }
          }

          // Subministrament d'empresa & CNAE
          if ((_.get(values, 'residenceType') === 'business') && !_.get(values, "cnae")) {
            _.set(errors, "cnae", i18n.t('common:text.required_field'));
          }

          // Active Contract (A3)
          if (_.isNull(_.get(values, 'activeContract'))) {
            _.set(errors, "activeContract", i18n.t('common:text.required_field'));
          }

          // Tipus de subministrament
          if (_.isNull(_.get(values, 'residenceType'))) {
            _.set(errors, "residenceType", i18n.t('common:text.required_field'));
          }

          // Comer Origin
          if (comerOriginEnabled && !_.get(values, "comerOrigin")) {
            _.set(errors, "comerOrigin", i18n.t('common:text.required_field'));
          }
          let minFiles = _.get(Settings, 'newContract.invoiceRequired', false) ? 1 : 0;
          if (_.get('values', 'activeContract') === 'no') {
            minFiles = 0;
          }

          const fileErrors = validateFileUpload(values.invoice, {
            min: minFiles,
            minMessage: i18n.t('common:text.multiupload_validation_min'),
          });
          if (fileErrors) {
            _.set(errors, "invoice", fileErrors);
          }

          return Object.keys(errors).length
            ? errors
            : true
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          validating,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={0}>
              <Grid item xs={12} style={{marginBottom: 20}}>
                <Typography variant="h6" color="primary">
                  {i18n.t('common:text.contractation_about_home')}
                </Typography>
                <Typography variant="body2">
                  {i18n.t('common:text.contractacion_about_home_info')}
                </Typography>
              </Grid>
            </Grid>
            <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {i18n.t('common:text.contractation_cups_title')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="cups"
                    label={props.gas ? i18n.t('common:text.contractation_cups_electricity') : i18n.t('common:text.contractation_cups')}
                    component={CUPSField}
                    style={{width: "100%"}}
                    onUpdate={(value) => handleCUPSUpdate({ ...values, cups: value })}
                    cupsAvailability={props.cupsAvailability}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="helper-text">{i18n.t('common:text.contractation_cups_helper')}</div>
                </Grid>
              </Grid>
              <Divider style={{marginBottom: 20, marginTop: 20}}/>
              <Field name="address" component={AddressField}
                showMobile={_.get(Settings, 'newContract.showMobile', false)} showEmail={_.get(Settings, 'newContract.showEmail', false)} />
              <Divider style={{marginBottom: 20, marginTop: 20}}/>
              <Grid container spacing={0}>
                <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                  <Typography variant="h6">
                    {i18n.t('common:text.contractation_housing_consumption')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={!(_.get(values, 'residenceType') === 'business') ? 6 : 12}>
                  <Field
                    name="activeContract"
                    component={CurrentActiveContract}
                    onUpdate={handleUpdateActiveContract}
                  />
                  <Grid container spacing={matchesBreakpointMd ? 3 : 2}>
                    <Grid item xs={12} md={!(_.get(values, 'residenceType') === 'business') ? 12 : 4}>
                      <Field
                        name="residenceType"
                        component={ResidenceTypeSelect}
                        onUpdate={handleUpdateResidenceType}
                      />
                    </Grid>
                    { (_.get(values, 'residenceType') === 'business') &&
                      <Grid item xs={12} md={8}>
                        <Field
                          name="cnae"
                          component={CNAEField}
                        />
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
              {comerOriginEnabled &&
                <Grid item xs={12} md={6} style={{marginBottom: 20, marginTop: 20}}>
                  <Field
                    name="comerOrigin"
                    component={ComerOriginSelectField}
                    onAvailableComersUpdate={handleAvailableComersUpdate}
                  />
                </Grid>
              }
              {values.activeContract === 'yes' &&
                <>
                  <Divider style={{marginBottom: 20, marginTop: 20}}/>
                  <Typography variant="h6">
                    {i18n.t('common:text.contractation_last_invoice')}
                  </Typography>
                  <Paper style={{padding: 15}} variant="outlined">
                    <FlexRow>
                      <Field
                        name="invoice"
                        component={FileUploadField}
                        min={1}
                        max={3}
                        label={
                          i18n.t('common:text.contractation_last_invoice')
                          + `${_.get(Settings, 'newContract.invoiceRequired', false) ? "*" : ""}`
                        }
                        hint={i18n.t('common:text.contractation_last_invoice_helper')}
                        anotherLabel={i18n.t('common:text.contractation_last_invoice_add')}
                        removeLabel={i18n.t('common:text.remove')}
                        onUpdate={handleUpdateUploadedFiles}
                      />
                    </FlexRow>
                  </Paper>
                </>
              }
            </Box>

            {_.get(Settings, "features.leads", false) && (
              <div className="helper-text" style={{marginBottom: 20, marginTop: 25}}>
                {isAuthenticated ? (
                  leadsNote
                ) : (
                  <Field
                    name="leadsConsent"
                    label={leadsNote}
                    component={CheckboxField}
                  />
                )}
              </div>
            )}

            <div>
              <Button
                variant={'contained'}
                color={'primary'}
                type="submit"
                disabled={submitting || validating || (props.cupsAvailability.invalid !== null)}
              >
                {
                  submitting || validating ? (
                    <CircularProgress size={25} style={{marginRight: 5}} />
                  ) : null
                }
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutHome);
